import React from "react"
import SubscribeForm from "../components/subscribeForm"

const TestPage = () => {

  return (
    <main>
      <SubscribeForm/>
    </main>
  )
}

export default TestPage
