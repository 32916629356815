import React, {useState} from 'react'
import tw from 'tailwind.macro'

const Label = tw.label`
  font-sans mb-2 text-black
`;

const Field = tw.input`
  font-sans bg-transparent border-black border-2 px-2 text-black
`;

const Btn = tw.button`
  m-0 text-white uppercase bg-black p-2 px-4 inline-block no-underline border-black
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function SubscribeForm(props) {
  const [submission, setSubmission] = useState({});

  const handleChange = (e) => {
    setSubmission({ ...submission, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...submission,
      }),
    })
      .then(() => props.close())
      .catch((error) => alert(error))
  }

  return (
    <div>
      <h2 className="text-black text-xl mb-4">We have more info on the way</h2>
      <p className="text-black text-md mb-4">
        If you're ready to start a project you can <a href="mailto:andrew@weareoust.co" className="underline">contact us here</a> but in the meantime give us your email and we'll let you know when we have some client work for you to look at ;)
      </p>
      <form
        name="subscribeForm" 
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        id="subscribe-form"
      >
        <input type="hidden" name="form-name" value="subscribeForm" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>
        <Label htmlFor="email">
          Email Address
        </Label>
        <div className="flex">
          <Field required name="email" type="email" onChange={handleChange} placeholder="your@email.com"/>
          <Btn>Submit</Btn>
        </div>
      </form>
    </div>
  )
}
